<template>
    <div>
        <div class="zx-banner-bar" id="index">
            <div class="swiper-container swiper-container1">
                <div class="swiper-wrapper ">
                    <div class="swiper-slide">
                        <div class="zx-banner-list">
                            <img class="banner-1" src="../../assets/banner.png" alt="">
                            <img class="banner-2" src="../../assets/banner-2.png" alt="">
                            <div class="banner-text">
                                <h3>数据生产要素流通的<span>守护者</span></h3>
                                <p>推动数据要素聚合，并通过隐私计算技术构建商业银行间数据安全流通网络，为金融机构提供安全可靠的数据要素服务，持续深耕金融行业场景化应用。</p>
                                <div class="button-bar">
                                    <a @click="$router.push('/aboutUs');$parent.curNavName = 'aboutUs'">了解更多</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="zx-prod-cont-bar" >
            <div class="zx-prod-cont-title">
                <h2>行业痛点</h2>
            </div>
            <div class="zx-programme-sens-1">
                <div>
                    <img src="../../assets/product/zx-programme-sens-1-img-1.png" alt="">
                    <h3>电信网络诈骗持续高发</h3>
                    <p>全国电信网络诈骗案迅猛增态势，特别是新冠疫情背景下，人们生产生活加速向网上转移，进一步加剧了案件高发。</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-programme-sens-1-img-2.png" alt="">
                    <h3>犯罪手段日新月异</h3>
                    <p>已知的电信诈骗的案件类型多达50余种，而且犯罪手段日趋多样化，流动性、突发性日益明显，甄别难度越来越大。</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-programme-sens-1-img-3.png" alt="">
                    <h3>反诈工作单线作战</h3>
                    <p>大多银行的反诈防控工作集中在本行风险账户，忽略犯罪分子流窜作案的特征，银行间缺乏风险共享和预警的机制。</p>
                </div>
                <div>
                    <img src="../../assets/product/zx-programme-sens-1-img-4.png" alt="">
                    <h3>反诈法提出更高要求</h3>
                    <p>2022年出台的《反电信网络诈骗法》明确了金融机构的主体责任，也对银行的业务安全风控能力提出了更高要求。</p>
                </div>
            </div>
        </div>
        <div class="zx-prod-cont-bar">
            <div class="zx-prod-cont-title">
                <h2>解决方案</h2>
            </div>
            <div class="zx-programme-sens-2">
                <h3 style="width: 1200px;font-size: 20px;font-weight: normal;">“银账保”账户风险防控平台，是围绕人民银行监管要求，以降低涉案风险账户为目的，为人民银行及商业银行提供的区域级账户风险联防联控的一站式服务平台，并支持Web/API多形态接入。</h3>
                <div class="zx-programme-sens-2-box">
                    <div class="zx-programme-sens-2-box-left">
                        <h3>银行账户风 险防控平台</h3>
                        <p>通过慧通安全计算平台，满足商业银行、监管机构、通信运营商在数据不出本地的前提下，进行数据安全共享，解决数据孤岛问题，使商业银行可利用共享数据，快速识别风险账户。</p>
                        <p>通过联邦学习技术，各商业银行可共享风险特征，共建账户风险模型，进一步提升账户风险识别能力。</p>
                    </div>
                    <div class="zx-programme-sens-2-box-right">
                        <img src="../../assets/product/zx-programme-sens-2-box-right-img.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="zx-prod-cont-bar">
            <div class="zx-prod-cont-title">
                <h2>优势沉淀</h2>
            </div>
            <div class="zx-programme-advantage-box">
                <ul>
                    <li>
                        <div>
                            <h3>联接</h3>
                            <p>专注金融场景创新，通过“慧通”数据安全流通平台，构建金融监管机构、金融机构、清算机构、通信运营商等机构间的数据安全流通网络</p>
                        </div>
                        <img src="../../assets/product/zx-programme-advantage-box-icon-1.png" alt="">
                    </li>
                    <li>
                        <div>
                            <h3>渠道</h3>
                            <p>累计服务超过200家银行、支付机构，具有辐射全国各类金融机构及非金融支付机构的渠道拓展能力</p>
                        </div>
                        <img src="../../assets/product/zx-programme-advantage-box-icon-2.png" alt="">
                    </li>
                </ul>
                <ul>
                    <li>
                        <img src="../../assets/product/zx-programme-advantage-box-icon-3.png" alt="">
                        <div>
                            <h3>资源</h3>
                            <p>平台已与银行卡、手机号、身份证、营业执照等信息的权威信源完成技术对接，具备快速提供各类数据服务的优势</p>
                        </div>
                    </li>
                    <li>
                        <img src="../../assets/product/zx-programme-advantage-box-icon-4.png" alt="">
                        <div>
                            <h3>资源</h3>
                            <p>核心团队来自商业银行、通信运营商以及知名互联网企业，对大数据、人工智能、隐私计算等技术在金融行业的应用具有丰富经验和很强的创新能力。</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import 'swiper/dist/js/swiper'
    import 'swiper/dist/css/swiper.css'
    import Swiper from "swiper"

    export default {
        name: 'App',
        data(){
            return {
                curNavName: '首页',
                navlist:[
                    {
                        name:'首页',
                        id: 'index'
                    },
                    {
                        name:'产品',
                        id: 'product'
                    },
                    {
                        name:'解决方案',
                        id: 'solution'
                    },
                    {
                        name:'客户案例',
                        id: 'partners'
                    },
                    {
                        name:'关于我们',
                        id: 'aboutUs'
                    }
                ]
            }
        },
        mounted() {
            new Swiper('.swiper-container1', {
                direction: 'horizontal', // 垂直切换选项
                //mousewheel: true, //滚轮
                // autoplay: { //自动开始
                //     delay: 2500, //时间间隔
                //     disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                // },
                loop: true, // 循环模式选项
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true, // 分页器可以点击
                // },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next1',
                    prevEl: '.swiper-button-prev1',
                },
            })
            new Swiper('.swiper-container2', {
                direction: 'horizontal', // 垂直切换选项
                //mousewheel: true, //滚轮
                // autoplay: { //自动开始
                //     delay: 2500, //时间间隔
                //     disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                // },
                slidesPerView: 4,
                loop: true, // 循环模式选项
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true, // 分页器可以点击
                // },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next2',
                    prevEl: '.swiper-button-prev2',
                },
            })
        },
    }
</script>

<style lang="scss" scoped>
    .zx-nav-bar {
        height: 83px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        background: #fff;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
        .zx-nav-box {
            width: 1200px;
            height: 83px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .zx-nav {
            display: flex;
            height: 83px;
            align-items: center;

            li {
                padding: 0 22px;
                height: 83px;
                line-height: 83px;
                transition: all .5s;
            }
            a{
                text-decoration: none;
                color: #000000;
                width: 100%;
                height: 100%;
                display: inline-block;
            }
            li:hover {
                background: #305DC4;
                color: #fff;
                cursor: pointer;
                transition: all .5s;
                a{
                    color: #fff;
                    transition: all .5s;
                }
            }

            li.active {
                background: #305DC4;
                color: #fff;
                cursor: pointer;
                transition: all .5s;
                a{
                    color: #fff;
                    transition: all .5s;
                }
            }
        }
    }

    .zx-banner-bar {
        height: 595px;
    }

    .swiper-container1 {
        height: 100%;
        background: #151419;
    }

    .zx-banner-list {
        position: relative;
        height: 100%;
        overflow: hidden;

        img.banner-1 {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-1039px, 0);
        }

        img.banner-2 {
            position: absolute;
            left: 50%;
            bottom: 143px;
            transform: translate(-700px, 0);
        }

        .banner-text {
            position: absolute;
            width: 690px;
            left: 45%;
            top: 112px;
            transform: translate(50px, 0);
            display: flex;
            flex-direction: column;

            h3 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 56px;
                line-height: 78px;
                color: #FFFFFF;
                margin-bottom: 20px;

                span {
                    color: #85B3FF;
                }
            }

            p {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 300;
                font-size: 28px;
                line-height: 176.02%;
                color: #FFFFFF;
                text-align: left;
            }
            .button-bar{
                display: flex;
                justify-content: flex-end;
                margin-top: 50px;
            }

            a {
                display: block;
                color: #305DC4;
                width: 194px;
                height: 60px;
                line-height: 60px;
                left: 1554px;
                top: 526px;
                background: #FFFFFF;
                border-radius: 2px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                color: #305DC4;
                cursor: pointer;
                transition: all .5s;
            }
            a:hover{

                background: #305DC4;
                color: #fff;
                transition: all .5s;
            }
        }
    }

    .zx-product-bar{
        width: 100%;
        height: 750px;
        display: flex;
        justify-content: center;
        background: #ffffff;
    }
    .zx-product-box{
        width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .zx-product-title{
            margin-top: 109px;
            margin-bottom: 80px;
            position: relative;
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }
            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }
            &::before{
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }
        .zx-product-sub-nav{
            display: flex;
            width: 390px;
            justify-content: space-between;
            margin-bottom: 45px;
            li{
                width: 120px;
                height: 43px;
                line-height: 43px;
                left: 761px;
                top: 974px;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                cursor: inherit;
            }
        }
        .zx-product-list{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 66px;
            li{
                width: 380px;
                height: 391px;
                background: #FFFFFF;
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
                display: flex;
                align-items: center;
                flex-direction: column;

                img{
                    width: 380px;
                    height: 195px;
                    margin-bottom: 7px;
                }
                h3{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 34px;
                    color: #000000;
                    margin-bottom: 10px;
                }
                p{
                    width: 330px;
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 29px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.75);
                }
            }
        }
        .more-btn{
            box-sizing: border-box;
            width: 158px;
            height: 41px;
            padding-right: 40px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
            align-items: center;
            line-height: 41px;
            position: relative;
            cursor: pointer;
            transition: all .5s;
            &:hover{
                background-color: #305DC4;
                color: #fff;
                transition: all .5s;
            }
            &::after{
                content: '';
                position: absolute;
                width: 40px;
                height: 40px;
                right: 0px;
                top: -1px;
                background: #000000;
            }
            .array{
                position: absolute;
                right: 10px;
                z-index: 2;
                top: 15px;
            }
        }
    }

    .zx-solution-bar{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #fff;
    }
    .zx-solution-box{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .zx-solution-title{
            margin-top: 107px;
            margin-bottom: 64px;
            position: relative;
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }
            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }
            &::before{
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }

        .zx-solution-banner{
            width: 100%;
            height: 548px;
            background-image: url("../../assets/solution-banner.png");
            background-repeat: no-repeat;
            background-size: cover;
        }
        .zx-solution-block{
            width: 100%;
            height: 548px;
            background-color: rgba(34, 53, 96, 0.95);
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        .zx-solution-content{
            width: 1214px;
            height: 394px;
            background: #305DC4;
            transform: translate(0, 226px);
            display: flex;
            justify-content: space-between;
            padding:  0 27px;
            box-sizing: border-box;
            img{
                transform: translate(0, -32px);
            }
            >div{
                width: 506px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-top: 76px;
                margin-right: 50px;
                h3{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 1;
                    color: #FFFFFF;
                    margin-bottom: 54px;
                    position: relative;
                    &::after{
                        content: '';
                        width: 42px;
                        height: 2px;
                        background: #FFFFFF;
                        border-radius: 5px;
                        position: absolute;
                        left: 0;
                        top:45px
                    }
                }
                p{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 180%;
                    color: #FFFFFF;
                    text-align: left;
                }
                >div{
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    a{
                        width: 68px;
                        height: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgba(255, 255, 255, 0.5);
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .zx-solution-bar{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #fff;
        margin-bottom: 190px;
    }
    .zx-partners-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #dfdfdf;

        .zx-partners-title {
            margin-bottom: 93px;
            position: relative;

            h2 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }

            h3 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }

            &::after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }

            &::before {
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }

        .zx-partners-list{
            display: flex;
            width: 1200px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 81px;
            botder-bottom: 1px solid rgba(217, 217, 217, 0.9);
        }
    }
    .swiper-container2{
        width: 1200px;
        ::v-deep .swiper-slide{
            height: 69px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .zx-footer{
        height: 434px;
        background: #F1F1F1;
        display: flex;
        justify-content: center;
    }

    .zx-footer-box{
        display: flex;padding-right: 100px;box-sizing: border-box;
        width: 800px;
        flex-direction: column;
        >div{
            display: flex;
        }
        .zx-footer-top{
            justify-content: space-between;
            padding-top: 43px;
            margin-bottom: 0px;
        }
        ul{
            display: flex;
            flex-direction: column;
            text-align: left;
            li.title{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 40px;
            }
            li{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 40px;
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    .zx-footer-bottom{
        margin-top: 43px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.5);
        >img{
            margin-bottom: 43px;
        }
        p{
            display: flex;
            align-items: center;
            margin-bottom: 43px;
            img{
                margin-right: 10px;
            }
        }
        a{
            list-style: none;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.5);
        }
    }
    .zx-end{
        width: 1200px;
        margin: 0 auto;
        height: 63px;
        display: flex;
        align-items: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.5);
        a{
            font-size: 20px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.5);
            text-decoration: initial;
            margin-left: 10px;
        }
    }
</style>


<style lang="scss">


    .zx-new-product-bar{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .zx-new-product-list{
            width: 340px;
            height: 440px;
            border: 0.5px solid rgba(0, 0, 0, 0.0);
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                color: #121212;
                margin-bottom: 20px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                color: rgba(18, 18, 18, 0.75);
                margin-bottom: 15px;
            }
            a{
                display: inline-block;
                width: 130px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #2653B8;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                color: #2653B8;
                cursor: pointer;
                &:hover{
                    background: #2653B8;
                    color: #fff;
                    transition: all .5s;
                }
            }
            &:hover{
                background: rgba(252, 252, 252, 0.5);
                border: 0.5px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                transition: all .5s;
            }
        }
    }


    .zx-new-partners-list-bar{
        display: flex;
        width: 1060px;
        justify-content: space-between;
        flex-wrap: wrap;
        .zx-new-partners-list{
            width: 520px;
            height: 370px;
            background: #FDFDFD;
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                color: #2653B8;
                margin-bottom: 15px;
            }
            a{
                display: inline-block;
                width: 130px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #2653B8;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                color: #2653B8;
                cursor: pointer;
                margin-top: 91px;
                &:hover{
                    background: #2653B8;
                    color: #fff;
                    transition: all .5s;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.1);
                transition: all .5s;
            }
        }
        .zx-new-partners-list:nth-child(1){
            background:url("../../assets/product/parner-bg-1.jpg") no-repeat 68px 40px;
        }
        .zx-new-partners-list:nth-child(2){
            background:url("../../assets/product/parner-bg-2.jpg") no-repeat 107px 54px ;
        }
        .zx-new-partners-list:nth-child(3){
            background:url("../../assets/product/parner-bg-3.png") no-repeat 63px 18px;
        }
        .zx-new-partners-list:nth-child(4){
            background:url("../../assets/product/parner-bg-4.png") no-repeat 121px 76px;
        }
    }


    .zx-new-aoubt-us-bar{
        h2{
            ont-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 42px;
            color: #000000;
            margin-bottom: 20px;
        }
        p{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 214%;
            color: rgba(18, 18, 18, 0.75);
        }

        margin-bottom: 50px;
    }



</style>
<style lang="scss" scoped>

    .zx-prod-cont-bar{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 140px;
        .zx-prod-cont-title{
            h2{
                font-family: 'PingFang HK';
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #121212;
                position: relative;
                margin-bottom: 118px;
                &:after{
                    content: '';
                    position: absolute;
                    width: 70px;
                    height: 6px;
                    background: #305DC4;
                    border-radius: 11px;
                    left: 50%;
                    margin-left: -35px;
                    top: 90px;
                }
            }
        }
    }

    .zx-programme-sens-1{
        width: 1200px;
        display: flex;
        justify-content: space-between;
        >div{
            width: 289px;
            height: 337px;
            background: #FFFFFF;
            box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
            img{
                width: 289px;
                height: 176.05px;
            }
            h3{
                height: 54px;
                line-height: 54px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                color: #000000;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 30px;
                /* or 214% */
                text-align: left;
                color: rgba(0, 0, 0, 0.5);
                padding: 0 10px;
            }
        }
    }

    .zx-programme-sens-2{
        >h2{
            width: 1200px;font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 176.02%;
            text-align: center;
            color: rgba(18, 18, 18, 0.75);
        }
    }
    .zx-programme-sens-2-box{
        display: flex;
        width: 1200px;
        margin-top: 26px;
        .zx-programme-sens-2-box-left{
            width: 670px;
            text-align: left;
            margin-top: 90px;
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 28px;
                line-height: 176.02%;
                color: #121212;
            }
            p{
                margin-top: 67px;
                width: 550px;
                height: 125px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 176.02%;
                color: rgba(18, 18, 18, 0.75);
            }
        }
    }

    .zx-programme-advantage-box{
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin-bottom: 80px;
        background: url("../../assets/product/zx-programme-advantage-box-bg.png") no-repeat center center;
        background-size: 355px;
        ul{
            width: 381px;
            li{
                width: 381px;
                height: 221px;
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
                background: #fff;
                margin-bottom: 25px;
                display: flex;
                justify-content: center;
                padding-top: 29px;
                img{
                    width: 73px;
                    height: 100px;
                }
                div{
                    width: 254px;
                    h3{
                        font-family: 'PingFang HK';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 28px;
                        line-height: 176.02%;
                        color: #121212;
                    }
                    p{
                        font-family: 'PingFang HK';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 176.02%;
                        letter-spacing: 0.09em;
                        color: rgba(18, 18, 18, 0.75);
                    }
                }
            }
            &:nth-child(1){
                li{
                    text-align: right;
                }
                img{
                    margin-left: 10px;
                }
            }
            &:nth-child(2){
                li{
                    text-align: left;
                }
                img{
                    margin-right: 10px;
                }
            }
        }
    }
</style>
